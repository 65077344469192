// Google analytics

export const GA_SHARE_EVENT_ACTION = 'share_event';

export const GA_SCHEDULE_EVENT_ACTION = 'schedule_event';

export const GA_SOCIAL_MEDIA_CATEGORY = 'social_media';

export const GA_SHARE_EVENT_IN_FACEBOOK_LABEL = 'Compartir evento en Facebook';

export const GA_SHARE_EVENT_IN_TWITTER_LABEL = 'Compartir evento en Twitter';

export const GA_SHARE_EVENT_VIA_MOBILE_LABEL = 'Compartir evento desde dispositivo mÃ³vil';

export const GA_SCHEDULE_EVENT_LABEL = 'Agendar evento en google calendar';

// Selectors

export const COLLAPSE_SELECTOR = '.collapse';

export const ELEMENT_WITH_TOOLTIP_SELECTOR = '[title]';

// Selectors - Sidebar

export const SIDEBAR_SELECTOR = '.sidebar';

export const SIDEBAR_ACTIVE_LINK_SELECTOR = '.sidebar-link.active';

// Selector - Navbar

export const NAV_SCROLL_HORIZONTAL_SELECTOR = '.nav-scroll-x';

export const NAV_SCROLL_RIGHT_CLASS = 'scroll-right';

export const NAV_SCROLL_LEFT_CLASS = 'scroll-left';

// Urls

export const API_CHART_URL = `${window.location.origin}/api/v1.0/charts/`;

// Data attributes

export const CONDITION_VALUE_DATA_ATTR = 'condition-value';

export const FAVORITE_URL_DATA_ATTR = 'favorite-url';

export const ATTEND_EVENT_URL_DATA_ATTR = 'attend-event-url';

// Maps

export const DEFAULT_MAPS_IDS = [
  '#mapDesktop',
  '#mapMobile'
];

export const DEFAULT_ICON_OPTIONS = {
  color: 'rgb(104, 191, 235)',
  fillOpacity: 1,
  iconSize: [41, 68]
};
